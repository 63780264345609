'use strict';

$(document).ready(function () {
	setSizes();

	/* SERVICE DIALER CLICKS */

	$(".circle-target").mouseover(function () {
		$('#services').removeClass('hidetips');
	});

	/*advantage hovers*/

	var circletimer = '';

	$("#advantage .circle").mouseenter(function () {

		if ($(this).hasClass('brighten')) {
			if (circletimer) {
				clearTimeout(circletimer);
			}
		}

		$(this).addClass('brighten');
	});
	$("#advantage .circle").mouseleave(function () {
		var circletimer = setTimeout(function () {
			$("#advantage .circle").removeClass('brighten');
		}, 4000);
	});

	// set glowbar interval
	var tid = setTimeout(glowloop, 3000);
	function glowloop() {
		var currentbar = $('.glow-bar.animate').data('barnumber');

		$('.glow-bar').removeClass('animate');
		$('.circle-radiator').removeClass('animate');
		var nextbar = currentbar + 1;
		if (nextbar > 3) {
			nextbar = 1;
		}
		var selectorbar = '.glow-bar.' + nextbar;
		$(selectorbar).addClass('animate');

		setTimeout(function () {
			var selectorcircle = '.circle-radiator.' + nextbar;
			$(selectorcircle).addClass('animate');
		}, 900);

		tid = setTimeout(glowloop, 3000); // repeat myself
		// no need to recall the function (it's an interval, it'll loop forever)
	}
	function abortTimer() {
		// to be called when you want to stop the timer
		clearInterval(tid);
	}

	$(".service-circle").click(function () {

		$('.service-inner').removeClass('active');

		var newrotate = parseFloat($(this).data('rotateto')) - ($('#wheel-ring1').data('iteration') % 1).toFixed(2);
		if (newrotate < 0) {
			console.log('neg' + Math.abs(newrotate));
			newrotate = 1 - Math.abs(newrotate);
		}
		var alreadyrotate = parseFloat($('#wheel-ring1').data('iteration'));
		var totalunits = newrotate + alreadyrotate;
		var degspin = 360 * totalunits.toFixed(3);
		var rotateval = "rotate(" + degspin + 'deg)';

		$('#services').addClass('hidetips');

		console.log(degspin + '||' + totalunits + '||' + alreadyrotate + '||' + newrotate);

		var circlefix = 360 * Math.abs(newrotate);
		var circlerotate = 'rotate(-' + degspin + 'deg)';
		console.log('fix' + circlefix);
		$('.circle-target').css('transform', circlerotate);

		if ($(window).width() < 768) {
			var rotateval = "rotate(" + $(this).data('rotatemobile') + 'deg)';
			var circlerotate = "rotate(-" + $(this).data('rotatemobile') + 'deg)';
		}

		var nextiteration = parseFloat(newrotate) + parseFloat($('#wheel-ring1').data('iteration'));
		console.log(nextiteration);
		$('#wheel-ring1').data('iteration', nextiteration);

		var setactive = '#details-' + $(this).attr('id');

		$('.service-inner').removeClass('active');
		setTimeout(function () {

			$('#wheel-ring1').css('transform', rotateval);

			$('.service-inner').removeClass('active');
			$('.service-circle').css('transform', circlerotate);
			$('#services .divider').removeClass('active');
			$('#services .divider').addClass('slide');
		}, 500);
		setTimeout(function () {
			$('#services .divider').removeClass('slide');
			$('#services .divider').addClass('active');
		}, 1000);
		setTimeout(function () {
			$(setactive).addClass('active');
		}, 1300);
		setTimeout(function () {
			$('#services').removeClass('hidetips');
		}, 2000);
	});

	$(".process-node").click(function () {
		$('.node-inner').removeClass('visible');
		$('.connector').removeClass('active');
		$('.process-node').removeClass('active');
		$('.process-node').removeClass('active');
		$('.process-node .indicator').removeClass('active');
		$(this).addClass('active');
		var selector = '#node-' + $(this).attr('id');
		var connector = '#connect-' + $(this).attr('id');
		var mobileconnector = '#mobile-connect-' + $(this).attr('id');
		$(this).addClass('active');
		$('.step-arrow.right').data('next', $(this).data('next'));
		$('.step-arrow.left').data('next', $(this).data('prev'));

		setTimeout(function () {
			$('.node-inner').removeClass('visible');
			$(selector).addClass('visible');
		}, 500);
		setTimeout(function () {
			$('.connector').removeClass('active');
			$(connector).addClass('active');
			$(mobileconnector).addClass('active');
		}, 650);
	});

	$(".step-arrow").click(function () {
		var next = '#' + $(this).data('next');
		if (!$(next).hasClass('active')) {
			console.log(next);
			$(next).click();
		}
	});

	/* CONVERT SVGS */
	/*
  * Replace all SVG images with inline SVG
  */
	$('img.svg-convert').each(function () {
		var $img = jQuery(this);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');

		jQuery.get(imgURL, function (data) {
			// Get the SVG tag, ignore the rest
			var $svg = jQuery(data).find('svg');

			// Add replaced image's ID to the new SVG
			if (typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if (typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass + ' replaced-svg');
			}

			// Remove any invalid XML tags as per http://validator.w3.org
			$svg = $svg.removeAttr('xmlns:a');

			// Replace image with new SVG
			$img.replaceWith($svg);
		}, 'xml');
	});
});

$(window).on('resize', function () {
	setSizes();
});

$(window).on('scroll', function () {
	var y_scroll_pos = window.pageYOffset;
	var scrolloffset = 250;

	if (y_scroll_pos > $('#about').offset().top - scrolloffset) {
		$('.divider-line').addClass('animate');
	}

	if (y_scroll_pos > $('#services').offset().top - scrolloffset) {

		if (!$('#services').hasClass('scrollpast')) {

			$('#services').addClass('scrollpast');

			setTimeout(function () {
				$('#wheel-ring1 .bg').addClass('visible');
			}, 100);

			setTimeout(function () {
				$('#wheel-ring1 .bg').addClass('fade');
				$('#services .divider').removeClass('slide');
				$('#services .divider').addClass('active');
				$('.circle-target').each(function (i) {
					var row = $(this);
					setTimeout(function () {
						if (!$(this).hasClass('visible')) row.addClass('visible');
					}, 250 * i);
				});
			}, 800);
			setTimeout(function () {
				$('#details-advisory').addClass('active');
			}, 1400);
		}
	}

	if (y_scroll_pos > $('#process').offset().top - scrolloffset) {

		if (!$('#process').hasClass('scrollpast')) {
			$('#assess').click();
			$('#process').addClass('scrollpast');
		}
	}

	if (y_scroll_pos > $('#sectors').offset().top - scrolloffset) {
		$('.sector-box').each(function (i) {
			var row = $(this);
			setTimeout(function () {
				if (!$(this).hasClass('visible')) row.addClass('visible');
			}, 5 * i);
		});

		$('.sector-box .icon').each(function (i) {
			var row = $(this);
			setTimeout(function () {
				if (!$(this).hasClass('visible')) row.addClass('visible');
			}, 100 + 150 * i);
		});

		$('.sector-box h3').each(function (i) {
			var row = $(this);
			setTimeout(function () {
				if (!$(this).hasClass('visible')) row.addClass('visible');
			}, 600 + 50 * i);
		});
	}
});

// handle links with @href started with '#' only
$(document).on('click', 'a[href^="#"]', function (e) {
	// target element id
	var id = $(this).attr('href');

	// target element
	var $id = $(id);
	if ($id.length === 0) {
		return;
	}

	// prevent standard hash navigation (avoid blinking in IE)
	e.preventDefault();

	// top position relative to the document
	var pos = $id.offset().top;

	$('.navbar-nav').addClass('scrolling');

	// animated top scrolling
	$('body, html').animate({ scrollTop: pos }, 700);

	setTimeout(function () {
		$('.navbar-nav').removeClass('scrolling');
	}, 701);
});

function setSizes() {
	$('.fullheight').height($(window).height());
	if ($(window).width() < 641) {
		$('.outer-wrap').width($(window).width());
		$('#drawing svg').attr('width', $(window).width());

		var divideheight = $('#about-text').height();

		$('#about .divider-line').css('max-height', divideheight + 'px');
	} else {

		$('.outer-wrap').width("100%");
	}
}
